import { uniqueId } from 'lodash-es'
import { request } from '@/utils'
import type { ProjInfoDesignQuoteCollectFeeSaveDTO } from '@/views/project/list/detail/requirements_new/components/PriceTotalView/types'
export interface PSite {
  id: string
  address: string
  height: number
  layoutFolderId: string
  layoutFolderFileCount: number
  length: number
  orderNo: number
  name: string
  peopleNum: number
  requireDesc: string
  width: number
}

export interface PScheme {
  id: string
  name: string
  siteIds: string[]
  site?: string
  templateId: string
  templateName: string
}

export interface PSchemeSiteInfo {
  designPaperFolderId: string
  effectPictureFolderId: string
  functionCodes: string[]
  functionText: string
  id: string
  remark: string
  tableLayoutCodes: string[]
  tableLayoutText: string
  designPaperFolderFileCount: number
  effectPictureFolderFileCount: number
}

export interface QuoteListType {
  id: string
  uid: string
  local: {
    copy: boolean
    paste: boolean
    recommend: boolean
  }
  selected: boolean
  list: QuoteList[]
  type1Id: string
  type1Name: string
  typeId: string
  typeName: string
}

export interface QuoteList {
  brand: string
  brandName: string
  categoryId: string
  categoryName: string
  categoryAlias: string
  classify: string
  classifyName: string
  costPrice: number
  costPriceHighlight: boolean
  costTotalFee: number
  id: string
  uid: string
  marketPrice: number
  marketPriceHighlight: boolean
  marketTotalFee: number
  model: string
  modelName: string
  newestMarketPrice: number
  newestCostPrice: number
  num: number
  remark: string
  rowHighlight: boolean
  series: string
  seriesName: string
  specsConfig: string
  technologyParameters: string
  unit: string
  unitName: string
  taxRate: string
  unitSupportDecimal: boolean
  isFree?: boolean
  selected: boolean
  local: {
    copy: boolean
    paste: boolean
    recommend: boolean
    status?: 'success' | 'error' | 'warning'
  }
  [key: string]: any
}
export interface PQuote {
  id: string
  listType: QuoteListType[]
}
export interface RType2 {
  id: string
  name: string
  listType2: { id: string; name: string; type1Id: string; type1Name: string; selected?: boolean }[]
}

export interface RQuoteConfig {
  canDown: boolean
  preSaleSure: boolean
  isOpenQuoteShowCostPrice: boolean
  updateVersion: string
  showColumn: string[]
  showColumnList: RQuoteConfigList[]
  watermarkCompany: string
  watermarkCompanyList: RQuoteConfigList[]
  updateVersionList: RQuoteConfigList[]
  showIdentifying: string[]
  identifyingList: RQuoteConfigList[]
}

export interface RQuoteConfigList {
  disabled: boolean
  label: string
  value: string
}

export interface PSaveFromCopyData {
  copyProjectId: string
  copySchemetId: string
  siteList: SiteList[]
}

export interface SiteList {
  copySiteId: string
  siteId: string
  siteName: string
  type: string
}

export interface RCopyData {
  type: string
  quoteSchemeData: {
    projectId: string
    schemeId: string
    schemeName: string
    siteIdList: { id: string; name: string }[]
  }
  quoteSiteData: {
    data: CopyDataTypeAndListDatum[]
  }
  quoteListData: CopyDataQuoteListData
}

export interface CopyDataQuoteListData {
  typeAndListData: CopyDataTypeAndListDatum[]
  listData: QuoteList[]
}

export interface CopyDataTypeAndListDatum {
  id: null
  type1Id: string
  type1Name: string
  typeId: string
  typeName: string
  selected: boolean
  list: QuoteList[]
}

export default {
  getCategoryTagAndType3V2: (data: any) => request.post('/project/project/design/category/v2/getCategoryTagAndType3V2', data),
  getCategoryBrand: (data: any) => request.post('/project/project/design/category/v2/getCategoryBrand', data),
  getCategoryModel: (data: any) => request.post('/project/project/design/category/v2/getCategoryModel', data),
  getCategoryPage: (data: any) =>
    request.post('/project/project/design/category/v2/getCategoryPage', data, { timeout: 1000 * 60 * 60 * 5 }),
  getCategoryImage: (params: any) => request.get('/project/project/design/category/v2/getCategoryImage', { params }),
  getSelectType2Tree: () => request.get<RType2[]>('/project/project/design/category/v2/getSelectCategoryType2Tree'),
  getCategoryName: (data: any) => request.post('/project/project/design/category/v2/getCategoryName', data),
  getCategoryType2: (data: any) => request.post('/project/project/design/category/v2/getCategoryType2', data),
  // 查询单个货品信息
  getCategoryInfo: (categoryId: string) =>
    request.get<{
      brand: string
      costPrice: number
      id: string
      marketPrice: number
      name: string
      unitCode: string
      unitName: string
    }>(`/project/project/design/category/v2/getCategoryInfo?categoryId=${categoryId}`),

  // 查询货品分类(包含1/2/3级)
  getCategoryTypeV2: (data: any) =>
    request.post('/project/project/design/category/v2/getCategoryType', data, { timeout: 1000 * 60 * 60 * 5 }),
  // 查询品类(品牌、系列、型号)
  getCategoryBrandSeriesModelV2: (data: any) =>
    request.post('/project/project/design/category/v2/getCategoryBrandSeriesModel', data, { timeout: 1000 * 60 * 60 * 5 }),

  createQuoteItem: ({
    type1Id,
    type1Name,
    typeId,
    typeName,
    list = [],
  }: {
    type1Id: string
    type1Name: string
    typeId: string
    typeName: string
    list: QuoteList[]
  }) => {
    return {
      id: '',
      uid: uniqueId(),
      local: {
        copy: false,
        paste: false,
        recommend: false,
      },
      selected: false,
      list,
      type1Id,
      type1Name,
      typeId,
      typeName,
    }
  },
  createQuoteChildItem: (data?: Partial<QuoteList>, paste = false) => {
    return {
      uid: uniqueId(),
      brand: '',
      brandName: '',
      categoryId: '',
      categoryName: '',
      classify: '',
      classifyName: '',
      costPrice: 0,
      costPriceHighlight: false,
      costTotalFee: 0,
      id: '',
      marketPrice: 0,
      marketPriceHighlight: false,
      marketTotalFee: 0,
      model: '',
      modelName: '',
      newestMarketPrice: 0,
      num: 1,
      remark: '',
      rowHighlight: false,
      series: '',
      seriesName: '',
      specsConfig: '',
      technologyParameters: '',
      unit: '',
      unitName: '',
      unitSupportDecimal: false,
      selected: false,
      isCanEdit: true,
      isCanUpdateCategory: true,
      isCanDelete: true,
      local: {
        copy: false,
        paste,
        recommend: false,
      },
      ...data,
    }
  },

  // 查询权限
  getAuth: (projectId: string, schemeId?: string) =>
    request.get(`/project/project/design/require/getAuth?projectId=${projectId}&schemeId=${schemeId}`),
  // 查询现场信息列表(公共)
  getCommonSiteList: (params: any) => request.get('/project/project/design/open/getSiteList', { params }),
  // 查询项目方案下所有会场列表
  getSchemeSiteList: (params: any) => request.get('/project/project/design/scheme/getSchemeSiteList', { params }),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),

  // 新增现场信息
  addSite: (data: Partial<PSite>, projectId: string) => request.post(`/project/project/design/site/add?projectId=${projectId}`, data),
  // 修改现场信息
  updateSite: (data: Partial<PSite>, projectId: string) => request.post(`/project/project/design/site/update?projectId=${projectId}`, data),
  // 查询会场详情
  getSiteDetail: (params: any) => request.get('/project/project/design/site/detail', { params }),
  // 删除会场
  deleteSite: (params: any) => request.get('/project/project/design/site/delete', { params }),
  // 获取项目附件
  getSiteFolder: (data: any, projectId: string) => request.post(`/project/project/design/site/getSiteFolder?projectId=${projectId}`, data),
  // 查询现场信息列表
  getSiteList: (projectId: string) => request.post(`/project/project/design/site/list?projectId=${projectId}`),

  // 新增报价方案
  addScheme: (data: Partial<PScheme>, projectId: string, templateId: string | undefined) =>
    request.post(`/project/project/design/scheme/template/add?projectId=${projectId}&templateId=${templateId}`, data),
  // 修改报价方案
  updateScheme: (data: Partial<PScheme>, projectId: string) =>
    request.post(`/project/project/design/scheme/update?projectId=${projectId}`, data),
  // 修改方案对应会场信息
  updateSchemeSite: (data: any, projectId: string) =>
    request.post(`/project/project/design/scheme/updateSchemeSite?projectId=${projectId}`, data),
  // 查询报价方案详情
  getSchemeDetail: (params: any) => request.get('/project/project/design/scheme/detail', { params }),
  // 删除报价方案
  deleteScheme: (params: any) => request.get('/project/project/design/scheme/delete', { params }),
  // 查询报价方案列表
  getSchemeList: (projectId: string) => request.post(`/project/project/design/scheme/list?projectId=${projectId}`),

  // 方案会场设计信息获取项目附件
  getSchemeSiteInfoFolder: (data: { folderId: string; id: string; type: '0' | '1' }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/schemeSiteInfo/getProjectFolder?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存方案会场设计信息
  saveSchemeSiteInfo: (data: Partial<PSchemeSiteInfo>, projectId: string, schemeId?: string, siteId?: string) =>
    request.post(`/project/project/design/schemeSiteInfo/save?projectId=${projectId}&schemeId=${schemeId}&siteId=${siteId}`, data),
  // 查询方案会场设计信息
  getSchemeSiteInfoDetail: (params: { projectId: string; schemeId?: string; siteId: string }) =>
    request.get('/project/project/design/schemeSiteInfo/detail', { params }),

  // 获取复制后口令
  getCopyCode: (data: {
    quoteListData?: { data?: Partial<QuoteListType[]> }
    quoteSchemeData?: { projectId: string; schemeId?: string; siteIdList?: string[] }
    quoteSiteData?: { data?: Partial<QuoteListType[]> }
    type: 'QUOTE_SCHEME' | 'QUOTE_SITE' | 'QUOTE_LIST'
  }) => request.post(`/project/project/design/copy/getCopyCode`, data),
  // 根据复制口令获取复制后数据
  getCopyData: (data: { code: string }) => request.post<RCopyData>(`/project/project/design/copy/getCopyData`, data),
  // 保存复制过来的结果
  saveFromCopyData: (data: Partial<PSaveFromCopyData>, projectId?: string, schemeId?: string) =>
    request.post(`/project/project/design/scheme/saveFromCopyData?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存报价方案下单个会场报价校验
  checkQuote: (data: Partial<PQuote>, projectId: string, schemeId: string, checkType: number) =>
    request.post(
      `/project/project/design/quote/getCheckSaveDataResult?projectId=${projectId}&schemeId=${schemeId}&checkType=${checkType}`,
      data
    ),
  // 保存报价方案下单个会场报价
  saveQuote: (data: Partial<PQuote>, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/save?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存报价清单模式是否开启查看成本价
  saveOpenQuoteShowCostPrice: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/saveOpenQuoteShowCostPrice?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存报价清单可下载设置
  saveDownFileConfig: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/saveDownFileConfig?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存售前定稿状态
  savePreSaleSure: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/savePreSaleSure?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 查询报价方案下单个会场报价
  getQuoteBySchemeSite: (params: { projectId: string; scene: '2' | '3'; schemeId: string; siteId: string }) =>
    request.get('/project/project/design/quote/getQuoteBySchemeSite', { params }),
  // 查询报价清单设置信息
  getQuoteConfig: (params: { projectId: string; schemeId: string }) =>
    request.get<RQuoteConfig>('/project/project/design/quote/getConfig', { params }),
  // 查询报价清单设备报价总金额（除去当前会场的总价）
  getQuoteTotalFeeBySchemeSite: (params: { projectId: string; schemeId: string; siteId: string }) =>
    request.get('/project/project/design/quote/getQuoteTotalFeeBySchemeSite', { params }),

  downloadSchemeFile: (projectId: string) =>
    request.post(`/project/project/design/require/downRequireSchemeFile/${projectId}`, null, {
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  downQuoteFile: (projectId: string, schemeId: string, data: Recordable) =>
    request.post('/project/project/design/quote/downQuoteFile', data, {
      params: {
        projectId,
        schemeId,
      },
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),

  // 查询综合费用修改详情
  getCollectFee: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/quote/getCollectFee', {
      params: {
        projectId,
        schemeId,
      },
    }),
  // 修改综合费用修改详情
  updateCollectFee: (projectId: string, schemeId: string, data: ProjInfoDesignQuoteCollectFeeSaveDTO) =>
    request.post('/project/project/design/quote/saveCollectFee', data, {
      params: {
        projectId,
        schemeId,
      },
    }),
  // 更新工时
  updateWorkHour: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/quote/getAutoQuoteDeviceTotalInstallWorkHour', {
      params: {
        projectId,
        schemeId,
      },
    }),

  getEmtpyCollectFee() {
    return {
      // 附加税金费
      additionalTaxFee: 0,
      // 附加税金费率
      additionalTaxRate: 0,
      // 可行性研究报告编制费
      availableReportFee: 0,
      // 设计费用
      designFee: 0,
      // 设备安装措施费费率
      deviceInstallMeasuresRate: 0,
      // 设备安装人工费工时
      deviceInstallWorkHour: 0,
      // 设备安装人工费总价（成本价总价）
      deviceInstallWorkerCostFee: 0,
      // 设备安装人工费单价
      deviceInstallWorkerPrice: 0,
      // 设备购置费
      deviceTotalFee: 0,
      // 设备购置费成本
      deviceCostTotalFee: 0,
      // 管理费用率
      manageRate: 0,
      // 政策性规费
      policyFee: 0,
      // 安全文明施工措施费率
      safeConstructeRate: 0,
      // 监理费率
      supervisionRate: 0,
      // 系统集成费率 50.02传50.02
      systemIntegrationRate: 0,
      // 总包管理费率
      totalPackageManageRate: 0,
      // 运杂费率
      transporteRate: 0,
      // 设备安装措施费计算标准（0：费率；1：金额）
      deviceInstallMeasuresComputeType: '0',
      // 设备安装措施费费
      deviceInstallMeasuresFee: 0,
      // 设备安装人工费单价（成本价）
      deviceInstallWorkerCostPrice: 0,
      // 优惠总价
      finalFee: 0,
      // 管理费计算标准（0：费率；1：金额）
      manageComputeType: '0',
      // 管理费
      manageFee: 0,
      // 安全文明施工措施费计算标准（0：费率；1：金额）
      safeConstructeComputeType: '0',
      // 安全文明施工措施费
      safeConstructeFee: 0,
      // 监理费计算标准（0：费率；1：金额）
      supervisionComputeType: '0',
      // 监理费
      supervisionFee: 0,
      // 总包管理费计算标准（0：费率；1：金额）
      totalPackageManageComputeType: '0',
      // 总包管理费
      totalPackageManageFee: 0,
      // 运杂费率
      transporteComputeType: '0',
      // 运杂费
      transporteFee: 0,
    }
  },
  // 查询配套推荐
  getCategoryMate: (data: any) => request.post('/project/project/design/category/v2/getCategoryMate', data),
  // 查询配套推荐(批量查询)
  getCategoryMateBatch: (data: any, project: string) =>
    request.post(`/project/project/design/category/v2/getCategoryMateV2?project=${project}`, data),
  // 提交品类信息补全申请
  purchaseSupplementNotify: (data: any, project: string, schemeId: string) =>
    request.post(`/project/project/design/quote/purchaseSupplementNotify?projectId=${project}&schemeId=${schemeId}`, data),
  // 查询合同清单标识列表
  getSchemeSiteListToTag: (data: { projectId: string; schemeId: string }) =>
    request.get(`/project/project/design/quote/getIdentifyingList`, {
      params: {
        projectId: data.projectId,
        schemeId: data.schemeId,
      },
    }),
  // 取消设为最终稿
  savePreSaleCancelFinalize: (projectId: string, schemeId: string) =>
    request.post(`/project/project/design/scheme/savePreSaleCancelFinalize?projectId=${projectId}&schemeId=${schemeId}`, {}),
  // 确定设为最终稿
  savePreSaleSureFinalize: (projectId: string, schemeId: string) =>
    request.post(`/project/project/design/scheme/savePreSaleSureFinalize?projectId=${projectId}&schemeId=${schemeId}`, {}),
  // 查询模板报价方案下报价
  getQuoteBySchemeSiteTemplate: (params: { projectId: string; schemeId: string; siteId: string; templateId: string }) =>
    request.get('/project/project/design/scheme/template/getQuoteBySchemeSite', { params }),
}
