import { request } from '@/utils'
import type { RequestConfig } from '~/types/axios'

export default {
  getServerTime: () =>
    request.post('/system/system/open/getCTime', null, {
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  getLoginInfo: () => request.post('/system/user/getLoginInfo'),
  getLoginFullInfo: () => request.post('/system/user/getLoginFullInfo'),
  refreshToken: () =>
    request.post('/auth/refreshToken', null, {
      noNeedTip: true,
    } as RequestConfig),

  getDictByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),

  getAllUserList: () => request.get('/system/system/open/getAllUser'),
  getAllRoleList: () => request.get('/system/system/open/getRole'),
  getAllEnableDeptList: () => request.get('/system/system/open/getDepartmentTreeEnabled'),
  getAllUserListNoAdmin: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),
  getDeptTree: () => request.get('/system/system/open/getDepartmentTree'),

  getProjectList: () => request.get('/project/project/open/getProjectFormSelectList'),
  getSelectUser: () => request.post('/system/system/open/getSelectUser', { code: 'all_people' }),
  getSelectUserByCode: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getAllUserWithLeave: () => request.post('/system/system/open/getSelectUserWithLeave'),
  /**
   * 个性化接口
   */
  updateCustomSetting: (data: Recordable) => request.post('/system/sys/userCenter/saveUserPrivateSet', data),
}
