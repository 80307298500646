import { request } from '@/utils'

export default {
  getPurchaseList: (data: any) =>
    request.post(`project/sys/project/infoContract/getPurchaseList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data),
  clearPurchaseCache: (projectId: string) => request.get(`/project/sys/project/infoContract/clearPurchaseCache/${projectId}`),
  savePurchaseCache: (data: any) => request.post('/project/sys/project/infoContract/savePurchaseCache', data),
  getAllPurchaseListId: (projectId: string) =>
    request.post(`/project/sys/project/infoContract/getAllPurchaseListId?projectId=${projectId}`),
  getPurchaseListCache: (data: any) =>
    request.post(`project/sys/project/infoContract/getPurchaseListCache?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getApproveRecodeList: (data: any) =>
    request.post(
      `project/sys/project/infoContract/getApproveRecodeList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&pageQueryFlag=${data.pageQueryFlag}`,
      data
    ),
  getProjectLimitListBySale: (projectId: string, itemId: string) =>
    request.post(`/project/sys/project/infoContract/getProjectLimitListBySale?projectId=${projectId}&itemId=${itemId}`),
  getApproveRecodeListTotal: (data: any) => request.post('/project/sys/project/infoContract/getApproveRecodeListTotal', data),

  // 修改结算金额
  changeSettlementAmount: (data: any) => request.post('/project/sys/project/settlementChange/changeSettlement', data),
  // 查询结算开票计划列表
  settlementInvoicePlanList: (data: any) => request.post(`/project/sys/project/settlementChange/settlementInvoicePlanList`, data),
  // 获取关联流程
  getWorkFlow: (projectId: string) => request.get(`/data/project/customer/track/queryProduct/${projectId}`),
}
