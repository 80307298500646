import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)
  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),
  getDirectionByCode: (params: any) => Middleware(api.getDirectionByCode, params),
  getCategory: () => Middleware(api.getCategory, {}),
  getChinaProvinceCityRegion: () => Middleware(api.getChinaProvinceCityRegion, {}),
  getBrand: () => Middleware(api.getBrand, {}),
  getPurchase: (params: any) => Middleware(api.getPurchase, params),
  getPartnerDetail: (params: any) => Middleware(api.getPartnerDetail, params),

  getProjectReport: (params: any) => Middleware(api.getProjectReport, params),
  getProjectTab: (params: any) => Middleware(api.getProjectTab, params),
  getProjectDownReport: (params: any) => Middleware(api.getProjectDownReport, params, true),
  getProjectCollectInfo: (params: any) => Middleware(api.getProjectCollectInfo, params),
  getCustomerCompanyAll: () => Middleware(api.getCustomerCompanyAll, {}),

  getProjectMoneyPage: (params: any) => Middleware(api.getProjectMoney, params),
  getProjectMoneyDetail: (params: any) => Middleware(api.getProjectMoneyDetail, params),
  getProjectMoneyDown: (params: any) => Middleware(api.getProjectMoneyDown, params, true),
  getProjectMoneyCollectInfo: (params: any) => Middleware(api.getProjectMoneyCollectInfo, params),
  getSaleMoneyCollectInfo: (params: any) => Middleware(api.getSaleMoneyCollectInfo, params),
  getCustomerCompanyCollectInfo: (params: any) => Middleware(api.getCustomerCompanyCollectInfo, params),

  getPurchaseDown: (params: any) => Middleware(api.getPurchaseDown, params, true),
  getPurchaseStatistics: (params: any) => Middleware(api.getPurchaseStatistics, params),
  getPurchasePage: (params: any) => Middleware(api.getPurchasePage, params),

  getCategoryPurchaseDown: (params: any) => Middleware(api.getCategoryPurchaseDown, params, true),
  getCategoryPurchaseStatistics: (params: any) => Middleware(api.getCategoryPurchaseStatistics, params),
  getCategoryPurchasePage: (params: any) => Middleware(api.getCategoryPurchasePage, params),

  getPurchaseOrderInfoByCode: (params: any) => Middleware(api.getPurchaseOrderInfoByCode, params),
  getOrderCreateUserList: () => Middleware(api.getOrderCreateUserList, {}),
  getOrderReportExport: (params: any) => Middleware(api.getOrderReportExport, params, true),
  getOrderReportList: (params: any) => Middleware(api.getOrderReportList, params),
  getOrderReportPartner: () => Middleware(api.getOrderReportPartner, {}),
  getOrderReportStatistics: (params: any) => Middleware(api.getOrderReportStatistics, params),

  getTaskByTaskId: (params: any) => Middleware(api.getTaskByTaskId, params),
  getZcTaskByTaskId: (params: any) => Middleware(api.getZcTaskByTaskId, params),

  getCustomerList: (params: any) => Middleware(api.getCustomerList, params),
  getUserBySelectCode: (params: any) => Middleware(api.getUserBySelectCode, params),
  getTaskCompanyName: () => Middleware(api.getTaskCompanyName, {}),
  getCompanyNameList: () => Middleware(api.getCompanyNameList, {}),
  getTaskProjectExpired: () => Middleware(api.getTaskProjectExpired, {}),
  getTaskProductExpired: () => Middleware(api.getTaskProductExpired, {}),
  getDeptList: () => Middleware(api.getDeptList, {}),
  getOpsMobileUserTaskCount: () => Middleware(api.getOpsMobileUserTaskCount, {}),
  getOpsResidentUserTaskCount: () => Middleware(api.getOpsResidentUserTaskCount, {}),
  getProjectAll: () => Middleware(api.getProjectAll, {}),

  getMobileTaskPage: (params: any) => Middleware(api.getMobileTaskPage, params),
  getMobileTaskReportStatistics: (params: any) => Middleware(api.getMobileTaskReportStatistics, params),
  getMobileTaskReportExport: (params: any) => Middleware(api.getMobileTaskReportExport, params, true),

  getResidentTaskPage: (params: any) => Middleware(api.getResidentTaskPage, params),
  getResidentTaskReportStatistics: (params: any) => Middleware(api.getResidentTaskReportStatistics, params),
  getResidentTaskReportExport: (params: any) => Middleware(api.getResidentTaskReportExport, params, true),

  getTaskUser: (params: any) => Middleware(api.getTaskUser, params),

  getSaleWeekReportPage: (params: any) => Middleware(api.getSaleWeekReportPage, params),
  getSaleWeekReportDown: (params: any) => Middleware(api.getSaleWeekReportDown, params, true),

  getSaleReturnMoneyweekReportPage: (params: any) => Middleware(api.getSaleReturnMoneyweekReportPage, params),
  getSaleReturnMoneyweekReportStatistics: (params: any) => Middleware(api.getSaleReturnMoneyweekReportStatistics, params),
  getSaleReturnMoneyweekReportDown: (params: any) => Middleware(api.getSaleReturnMoneyweekReportDown, params, true),

  getTagOpenToSelect: (params: any) => Middleware(api.getTagOpenToSelect, params),
  getLastestYear: () => Middleware(api.getLastestYear, {}),
  getProjLifecycl: () => Middleware(api.getProjLifecycl, {}),
  getSignYear: () => Middleware(api.getSignYear, {}),
  getCustomerById: () => Middleware(api.getCustomerById, {}),
  getCustomerCompanyReportPage: (params: any) => Middleware(api.getCustomerCompanyReportPage, params),
  getCustomerCompanyContractCollectInfo: (params: any) => Middleware(api.getCustomerCompanyContractCollectInfo, params),
  getCustomerCompanyContractReportPage: (params: any) => Middleware(api.getCustomerCompanyContractReportPage, params),
  customerCompanyReportExport: (params: any) => Middleware(api.customerCompanyReportExport, params, true),
  customerCompanyContractReportExport: (params: any) => Middleware(api.customerCompanyContractReportExport, params, true),
  getUserBelongToCode: () => Middleware(api.getUserBelongToCode, {}),
  getAllBrand: () => Middleware(api.getAllBrand, {}),
  getDetailCategory: (params: any) => Middleware(api.getDetailCategory, params, true),
  getProjectMoneyDownV2: (params: any) => Middleware(api.getProjectMoneyDownV2, params, true),
  getProjectMoneyDetailV2: (params: any) => Middleware(api.getProjectMoneyDetailV2, params),
  getProjectMoneyPageV2: (params: any) => Middleware(api.getProjectMoneyV2, params),
  getProjectMoneyCollectInfoV2: (params: any) => Middleware(api.getProjectMoneyCollectInfoV2, params),
}
